import React from 'react';
import WhyEYouthTeams from './WhyEYouthTeams/WhyEYouthTeams';
import FeaturesTrack from './FeaturesTrack/FeaturesTrack';
import Reporting from './Reporting/Reporting';
import IntroTeams from './IntroTeams/IntroTeams';
import image1 from '../../assets/Business/Main (1).png';
import image2 from '../../assets/Business/Main (2).png';
import image3 from '../../assets/Business/Main (3).png';
import image4 from '../../assets/Business/Main.png';
import ProductTestimonial from '../../components/ProductTestimonial/ProductTestimonial';
import LearningPlatform from './LearningPlatform/LearningPlatform';
import WhyService from '../../components/WhyService/WhyService';
import videos from "../../assets/Business/teamsIcon1.png";
import quiz from "../../assets/Business/teamsIcon2.png";
import dataChart from "../../assets/Business/teamsIcon3.png";
import joystick from "../../assets/Business/teamsIcon4.png";
import presentation from "../../assets/Business/teamsIcon5.png";



function ServiceTeams() {
    const featuresData = [
        {
            image: image4,
            title: 'Branded and Customized',
            description: 'Comes with your logo, unique URL, and customize user groups based on your company structure',
        },
        {
            image: image1,
            title: 'Smoothly Manageable',
            description: 'Create customized learning paths for employees, monitor progress in real-time, and extract comprehensive reports at all levels.',
        },
        {
            image: image2,
            title: 'Easy & Fast Access',
            description: 'Get access to EYouth content with interactive videos and external resources, without needing developers, on a user-friendly and secure platform with an attractive design.',
        },
        {
            image: image3,
            title: 'Engaging Experience',
            description: 'Engage learners by using the course discussion board to share comments and inquiries, and enhance the learning journey with interactive tools, assessments, assignments, and external resources',
        }
    ];
    const serviceStudioBenefits = [
        {
            logo: joystick,
            description: "Suitable",
            subDescription: "Subscriptionplans"
        },
        {
            logo: dataChart,
            description: "Delivered By ",
            subDescription: "MarketExperts"
        },
        {
            logo: presentation,
            description: "International Accredited ",
            subDescription: "Courses"
        },
        {
            logo: quiz,
            description: "Interactive ",
            subDescription: "LearningJourney"
        },
        {
            logo: videos,
            description: "Designed By Experienced ",
            subDescription: "Instructional Designers"
        },
    ];

    return (
        <div>
            <IntroTeams
                imageBackground='introImageTeams.png'
                title="The fastest and effortless way  to get your own branded,expandable learning platform is here"
                subTitle='Our learning platform comes with the most powerful monitoring and reporting capabilities'
            // Fontcolor="#653BEA"
            />
            <WhyEYouthTeams SectionTitle='Why EYouth Teams is different?' featuresData={featuresData} headTitle='Core Features' />
            <LearningPlatform />
            <FeaturesTrack />
            <Reporting />
            <WhyService
                title="Why EYouth Teams?"
                benefits={serviceStudioBenefits}
            />
            <ProductTestimonial />

        </div>
    );
}

export default ServiceTeams;