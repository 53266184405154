import React from "react";
import airplane from "../../assets/Business/classAirplane.jpg";
import classEmployee from "../../assets/Business/classImage.jpg";
import content1 from "../../assets/Business/content1.svg";
import content10 from "../../assets/Business/content10.svg";
import content11 from "../../assets/Business/content11.svg";
import content2 from "../../assets/Business/content2.svg";
import content3 from "../../assets/Business/content3.svg";
import content4 from "../../assets/Business/content4.svg";
import content5 from "../../assets/Business/content5.svg";
import content6 from "../../assets/Business/content6.svg";
import content7 from "../../assets/Business/content7.svg";
import content8 from "../../assets/Business/content8.svg";
import content9 from "../../assets/Business/content9.svg";
import flexibleClasses from "../../assets/Business/flexibleClass.jpg";
import screen from "../../assets/Business/screen.jpg";
import strongTeam from "../../assets/Business/strongTeam.jpg";
import whyclass1 from "../../assets/Business/whyClass1.svg";
import whyclass2 from "../../assets/Business/whyClass2.svg";
import whyclass3 from "../../assets/Business/whyClass3.svg";
import whyclass4 from "../../assets/Business/whyClass4.svg";
import class1 from "../../assets/Business/class1.jpg";
import class2 from "../../assets/Business/class2.jpg";
import class3 from "../../assets/Business/class3.jpg";
import IntroTeams from "../../pages/ServiceTeams/IntroTeams/IntroTeams";
import WhyEYouthTeams from "../../pages/ServiceTeams/WhyEYouthTeams/WhyEYouthTeams";
import ProductTestimonial from "../ProductTestimonial/ProductTestimonial";
import WhyService from "../WhyService/WhyService";
import styles from "./ServiceClass.module.css";

const ServiceClass = () => {
    const contentLibrary = [
        {
            logo: content1,
            description: "Leadership& Management",
        },
        {
            logo: content2,
            description: "Project & Quality Management",
        },
        {
            logo: content3,
            description: "HR Management",
        },
        {
            logo: content4,
            description: "Procurement & SupplyChain Management",
        },
        {
            logo: content5,
            description: "Sales & Business Development",
        },
        {
            logo: content6,
            description: "Accounting & Finance",
        },
        {
            logo: content7,
            description: "Interpersonal Skills& Self Development",
        },
        {
            logo: content8,
            description: "Marketing & advertising",
        },
        {
            logo: content9,
            description: "Technology & Data Science",
        },
        {
            logo: content10,
            description: "Administration & Office Management",
        },
        {
            logo: content11,
            description: "Engineering",
        },
    ];

    const whyEyouthClass = [
        {
            logo: whyclass1,
            description: "One Place for All Training Solutions",
        },
        {
            logo: whyclass2,
            description: "One Place for All Training Solutions",
        },
        {
            logo: whyclass3,
            description: "In-house Team of Learning Experts",
        },
        {
            logo: whyclass4,
            description: "Affordable Prices",
        },
    ];

    const featuresData = [
        {
            image: class1,
            title: 'Customized Training',
            description: 'Tailor-made training programs to fit your different training need.',
        },
        {
            image: class2,
            title: 'Open Enrollment Training',
            description: 'Open enrollment training programs in different learning topics to include learners from different companies.',
        },
        {
            image: class3,
            title: 'Learning Events',
            description: 'Team building events based on your need sthrough games and fun experiences.',
        }
    ];

    return (
        <>
            <IntroTeams
                imageBackground="serviceClass.jpeg"
                title="A comprehensive suite of training services"
                subTitle="EYouth Class is a leading corporate training service in the MENA
                            region and Africa, that provides qualified training programs in
                            the most needed skills for today’s work force."
            />

            <WhyEYouthTeams SectionTitle='We are dedicated to improving the efficiency of the learning industry in MENA and Africa' featuresData={featuresData} headTitle='Core Services' />

            <div className={styles.serviceContent}>
                <div
                    className={`mt-5 ${styles.commonArticle} ${styles.interactiveArticle}`}
                >
                    <img src={classEmployee} alt="interactive content" />
                    <div>
                        <h2 className={styles.serviceHeader}>
                            Unlock the real potential of your employees and make your team
                            more productive
                        </h2>
                        <h5 className={styles.serviceSubHeader}>
                            EYouth Class provide a comprehensive range of customized training
                            programs on technical and non-technical topics
                        </h5>
                        <ul className={styles.serviceStudioLists}>
                            <li>Interpersonal Skills</li>
                            <li> Management Skills</li>
                            <li>Technical Skills</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className={`container ${styles.customizedPrograms}`}>
                <h2 className={styles.serviceHeader}>
                    Our customized training programs ensure partner success through
                    comprehensive eLearning and development solution
                </h2>
                <img className="mx-3" src={airplane} alt="class airplane" />
            </div>

            <div className={`container ${styles.contentLibraries}`}>
                <h2>
                    Choose from a wide range of content libraries with over
                    <strong> 2,500 </strong>
                    different courses for you
                </h2>
                <div className={styles.cardDetailsContainer}>
                    {contentLibrary.map((content) => (
                        <div className={styles.cardsDetail}>
                            <img src={content.logo} alt="content logo" />
                            <h6>{content.description}</h6>
                        </div>
                    ))}
                </div>
            </div>

            <div className={styles.serviceContent}>
                <div
                    className={`mt-5 align-items-center ${styles.commonArticle} ${styles.interactiveArticle}`}
                >
                    <img src={flexibleClasses} alt="interactive content" />
                    <div>
                        <h2 className={styles.serviceHeader}>
                            A flexible training solution to accommodate varied preferences
                        </h2>
                        <h5 className={styles.serviceSubHeader}>
                            Our open enrollment courses are available on various dates and
                            durations, tailored to meet your employees' schedules.
                        </h5>
                        <ul className={styles.serviceStudioLists}>
                            <li>Live Online Training Programs</li>
                        </ul>
                    </div>
                </div>
                <div
                    className={`mt-5 ${styles.commonArticle} ${styles.smoothExperience}`}
                >
                    <img src={screen} alt="interactive content" />
                    <div className="pt-3">
                        <h2 className={styles.serviceHeader}>
                            Ensure your employees have a smooth and hassle-free experience
                            while accessing our top-quality pre-made courses
                        </h2>
                        <ul className={styles.serviceStudioLists}>
                            <li>Learn from Anywhere</li>
                            <li>Engage in real-time discussions</li>
                            <li>Lifetime access to Recorded Sessions</li>
                            <li>Access various learning styles</li>
                            <li>Apply on real business cases</li>
                        </ul>
                    </div>
                </div>
                <div
                    className={`mt-5 align-items-center ${styles.commonArticle} ${styles.interactiveArticle}`}
                >
                    <img src={strongTeam} alt="interactive content" />
                    <div>
                        <h2 className={styles.serviceHeader}>
                            Build a stronger team culture and increase productivity with our
                            team-building programs
                        </h2>
                        <h5 className={styles.serviceSubHeader}>
                            Through various engaging activities:
                        </h5>
                        <ul className={styles.serviceStudioLists}>
                            <li>Rope Games</li>
                            <li>Problem-Solving challenges</li>
                            <li>Game Cards</li>
                            <li>Interactive Boards</li>
                        </ul>
                    </div>
                </div>
            </div>

            <WhyService title="Why EYouth Class?" benefits={whyEyouthClass} />
            <ProductTestimonial />
        </>
    );
};

export default ServiceClass;
