import {
    Alert,
    Button,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    TextField,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { sendRequest } from "../../servises/api";
import styles from "./ContactUs.module.css";

function ContactUs() {
    // eslint-disable-next-line
    const httpsRegex =
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
    const numberRegex = /^[0-9]*$/;

    const [openAlert, setOpenAlert] = useState(false);
    const [loading, setLoading] = useState(false);

    const validationSchema = Yup.object({
        fullName: Yup.string()
            .trim()
            .required("Full Name is required")
            .min(3, "Minimum chars are 3"),
        workEmail: Yup.string()
            .trim()
            .email("Enter a valid email address")
            .required("Email is required"),
        phone: Yup.string()
            .required("Phone is required")
            .matches(numberRegex, "Only Numbers allowed"),
        companyName: Yup.string()
            .trim()
            .required("Company Name is required")
            .min(3, "Minimum chars are 3"),
        companySize: Yup.string().required("Company Size is required"),
        // .matches(numberRegex, "Only Numbers allowed"),
        websiteURL: Yup.string()
            .trim()
            .min(3, "Minimum chars are 3")
            .matches(httpsRegex, "Should match https://www.example.com"),
        serviceNeeded: Yup.string().trim().required("Service is required"),
    });
    const formik = useFormik({
        initialValues: {
            fullName: "",
            workEmail: "",
            phone: "",
            companyName: "",
            companySize: "",
            websiteURL: "",
            serviceNeeded: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (values, actions) => {
            setLoading(true);
            // console.log("Form Values:", values);

            try {
                const result = await sendRequest(
                    values.fullName,
                    values.phone,
                    values.workEmail,
                    values.companyName,
                    values.companySize,
                    values.websiteURL,
                    values.serviceNeeded
                );
                // console.log(result, "result");

                if (result.status === "received") {
                    formik.resetForm();
                    setOpenAlert(true);
                } else {
                    // console.log(result.json(), "contact us error");
                    throw new Error(result.json());
                }
            } catch (error) {
                console.error("Error from contact us", error);
            } finally {
                setLoading(false);
            }
        },
    });

    const companySizeValue = [
        {
            value: "50-100",
            text: "0-50",
        },
        {
            value: "100-250",
            text: "51-100",
        },
        {
            value: "500",
            text: ">250",
        },
    ];

    const ourService = [
        {
            value: "Teams",
            text: "EYouth Teams.",
        },
        {
            value: "Studio",
            text: "EYouth Studio",
        },
        {
            value: "Class",
            text: "EYouth Master",
        },
        {
            value: "LMS",
            text: "EYouth LMS",
        },
    ];

    return (
        <>
            <div className={styles.big_section}>
                <div className={styles.contactUs}>
                    <h1>
                        REQUEST A FREE DEMO, OR ASK US ABOUT OUR <strong>LEARNING</strong>{" "}
                        AND <strong>DEVELOPMENT</strong> SOLUTIONS
                    </h1>
                </div>

                <div className="container">
                    <div className={styles.sectionsContainer}>
                        <section id={styles.leftSec}>
                            <form
                                component="form"
                                autoComplete="true"
                                onSubmit={formik.handleSubmit}
                            >
                                <div className={styles.inputContainer}>
                                    <TextField
                                        className={styles.inputWidth}
                                        id="fullName"
                                        size="small"
                                        hiddenLabel
                                        color="primary"
                                        InputProps={{ disableUnderline: true }}
                                        variant="outlined"
                                        placeholder="Full Name"
                                        value={formik.values.fullName}
                                        name="fullName"
                                        autoComplete="true"
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.fullName && Boolean(formik.errors.fullName)
                                        }
                                        helperText={formik.errors.fullName}
                                    />
                                    <TextField
                                        className={styles.inputWidth}
                                        id="phone"
                                        hiddenLabel
                                        type="tel"
                                        size="small"
                                        InputProps={{ disableUnderline: true }}
                                        variant="outlined"
                                        placeholder="Phone Number"
                                        value={formik.values.phone}
                                        name="phone"
                                        autoComplete="true"
                                        onChange={(e) => {
                                            if (isNaN(e.target.value)) {
                                                e.preventDefault();
                                            } else {
                                                formik.handleChange(e);
                                            }
                                        }}
                                        error={formik.touched.phone && Boolean(formik.errors.phone)}
                                        helperText={formik.errors.phone}
                                    />
                                    <TextField
                                        className={styles.inputWidth}
                                        id="workEmail"
                                        hiddenLabel
                                        size="small"
                                        InputProps={{ disableUnderline: true }}
                                        variant="outlined"
                                        placeholder="Work Email"
                                        value={formik.values.workEmail}
                                        name="workEmail"
                                        autoComplete="true"
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.workEmail &&
                                            Boolean(formik.errors.workEmail)
                                        }
                                        helperText={formik.errors.workEmail}
                                    />
                                    <div className={styles.combinedField}>
                                        <TextField
                                            className={styles.inputWidth}
                                            id="companyName"
                                            size="small"
                                            hiddenLabel
                                            InputProps={{ disableUnderline: true }}
                                            variant="outlined"
                                            placeholder="Company Name"
                                            value={formik.values.companyName}
                                            name="companyName"
                                            autoComplete="true"
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.companyName &&
                                                Boolean(formik.errors.companyName)
                                            }
                                            helperText={formik.errors.companyName}
                                        />
                                        <Select
                                            className={styles.inputWidth}
                                            name="companySize"
                                            id="companySize"
                                            labelId="companySizeLabel"
                                            label="Select company size"
                                            value={formik.values.companySize || ""}
                                            error={formik.touched.companySize && Boolean(formik.errors.companySize)}
                                            size="small"
                                            variant="outlined"
                                            displayEmpty
                                            input={<OutlinedInput />}
                                            inputProps={{ "aria-label": "Company size" }}
                                            helperText={formik.errors.companySize}
                                            onChange={(event) => formik.setFieldValue("companySize", event.target.value)}
                                        >
                                            <MenuItem value="" disabled>
                                                Company size
                                            </MenuItem>
                                            {companySizeValue.map((ele, i) => (
                                                <MenuItem key={i} value={ele.value}>
                                                    {ele.text}
                                                </MenuItem>
                                            ))}
                                        </Select>

                                    </div>
                                    <div className={styles.combinedField}>
                                        <TextField
                                            className={styles.inputWidth}
                                            id="websiteURL"
                                            hiddenLabel
                                            size="small"
                                            InputProps={{ disableUnderline: true }}
                                            variant="outlined"
                                            placeholder="Website URL"
                                            value={formik.values.websiteURL}
                                            name="websiteURL"
                                            autoComplete="true"
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.websiteURL &&
                                                Boolean(formik.errors.websiteURL)
                                            }
                                            helperText={formik.errors.websiteURL}
                                        />

                                        <Select
                                            className={styles.inputWidth}
                                            name="serviceNeeded"
                                            id="serviceNeeded"
                                            labelId="serviceNeededLabel"
                                            label="Select the Service you need"
                                            value={formik.values.serviceNeeded || ""}
                                            error={
                                                formik.touched.serviceNeeded &&
                                                Boolean(formik.errors.serviceNeeded)
                                            }
                                            size="small"
                                            variant="outlined"
                                            input={<OutlinedInput />}
                                            inputProps={{ "aria-label": "Service Needed" }}
                                            helperText={formik.errors.serviceNeeded}
                                            onChange={(e) =>
                                                formik.setFieldValue("serviceNeeded", e.target.value)
                                            }
                                            displayEmpty
                                        >
                                            <MenuItem value="" disabled>
                                                Select Services
                                            </MenuItem>
                                            {ourService.map((ele, i) => (
                                                <MenuItem key={i} value={ele.value}>
                                                    {ele.text}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </div>
                                </div>

                                <div className={styles.formButtons}>
                                    <Button
                                        className={styles.businessSubmitBtn}
                                        // disabled={loading}
                                        variant="contained"
                                        type="submit"
                                        sx={{
                                            background: "#183696",
                                            "&:hover": {
                                                background: "black",
                                            },
                                        }}
                                    >
                                        {loading ? "Submitting" : "Submit"}
                                    </Button>
                                </div>
                            </form>
                            {openAlert && (
                                <Stack
                                    sx={{ width: "75%", margin: "0px auto" }}
                                    className="mt-3"
                                    spacing={2}
                                >
                                    <Alert
                                        onClose={() => {
                                            setOpenAlert(false);
                                        }}
                                    >
                                        Your request was sent successfully.
                                    </Alert>
                                </Stack>
                            )}
                        </section>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ContactUs;
