import React from "react";
import FaceIcon from '@mui/icons-material/Face';
import reviewIcon from "../../assets/Business/reviewIcon.svg";
import stars from "../../assets/Business/stars.svg";
import styles from "./ProductTestimonial.module.css";
import customer1 from "../../assets/Business/work-with1.jpg"
import customer2 from "../../assets/Business/work-with2.jpg"
import customer3 from "../../assets/Business/work-with3.jpg"
import customer4 from "../../assets/Business/work-with4.jpg"
import customer5 from "../../assets/Business/work-with5.jpg"
import customer6 from "../../assets/Business/work-with6.jpg"
import customer7 from "../../assets/Business/work-with7.png"
import customer8 from "../../assets/Business/work-with8.jpg"
import { Link } from "react-router-dom";
// import { Button } from "react-bootstrap";


const ProductTestimonial = () => {
    return (
        <div className="my-5">
            <div className={styles.goalsAchieved} >
                <h3 className="text-center">Proud to work with</h3>
                <div className={`my-5 text-center ${styles.div_partenars}`}>
                    <div className="d-flex justify-content-evenly mb-3">
                        <img width="20%" src={customer1} alt="customer1" />
                        <img width="20%" src={customer2} alt="customer2" />
                        <img width="20%" src={customer3} alt="customer3" />
                        <img width="20%" src={customer4} alt="customer4" />
                    </div>
                    <div className="d-flex justify-content-evenly">
                        <img width="20%" src={customer5} alt="customer5" />
                        <img width="20%" src={customer6} alt="customer6" />
                        <img width="20%" src={customer7} alt="customer7" />
                        <img width="20%" src={customer8} alt="customer8" />

                    </div>
                </div>
                {/* <Button variant="primary" className={styles.moreCustomersBtn}>
                    Load more
                </Button> */}
            </div>

            <div className={`container ${styles.achievementSec}`}>
                <div className={styles.goalsAchieved_1} >
                    <h3>Powering the growth of 100+ business & retailers in Egypt.</h3>
                    <h6>From single store, startups, to large multi-store brands.</h6>
                </div>
                <div className={styles.reviews}>
                    <img src={reviewIcon} alt="review icon" />
                    <img className="mx-3" src={stars} alt="stars" />
                    <p>
                        “With EYouth, we’re able to easily track our performance in full
                        detail. It’s become an essential tool for us to grow and engage with
                        our Team.”
                    </p>
                    <div className={styles.personReview}>
                        <FaceIcon sx={{ fontSize: "3rem" }} />
                        <div>
                            <h4>Ronald Hart</h4>
                            <h6>Digital Marketing Executive, Hypebeast</h6>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mb-3">
                <div className={styles.requestQuoteSec}>
                    <h2>Grow your team now!</h2>
                    <h6>Join with more 1200+ happy customers</h6>
                    <Link to="/contactus" >
                        <button className='btn btn-dark'>Request a quote</button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default ProductTestimonial;
