import React from 'react'
import checkImage from '../../../assets/Business/feather_check-circle.png'
import styles from './FeaturesTrack.module.css'
import FeaturesTrackImage from '../../../assets/Business/image with arrow.png'

function FeaturesTrack() {
    return (
        <div className='d-flex container align-items-start mt-4'>
            <div className={`col-6 ${styles.FeaturesTrackImage}`}>
                <img className={`w-75 `} src={FeaturesTrackImage} alt="" />
            </div>
            <div className={`col-6 mt-4 ${styles.FeaturesTrackDiv}`}>
                <h4 className={`${styles.text_color} ${styles.line_height} mb-3 `}>Multiple features for L&D teams to easily track and manage the learning experience</h4>
                <ul className={styles.UnorderedList} >
                    <li className={`mb-3`} ><img className='mx-2' src={checkImage} alt='check' />Create personalized and collective learning paths</li>
                    <li className={`mb-3`} ><img className='mx-2' src={checkImage} alt='check' />Set deadlines</li>
                    <li className={`mb-3`} ><img className='mx-2' src={checkImage} alt='check' />Assign & Delete Courses</li>

                </ul>

            </div>
        </div>

    )
}

export default FeaturesTrack