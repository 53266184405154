import React from 'react';
import line from '../../../assets/Business/underline.png';
import styles from './IntroTeams.module.css';
function IntroTeams(props) {
    return (
        <div className={styles.introBackground} style={{ backgroundImage: `url(${require('../../../assets/Business/' + props.imageBackground)})` }}
        >
            <div className={`${styles.BackgroundIntroDivMobile}`}>
                <h1 className={`mx-5 ${styles.firstSentences}`} style={{ color: props.Fontcolor }}>
                    {props.title}
                </h1>
                <img className='mx-5' width="300px" src={line} alt="underline" />
                <p className={`mx-5 my-2 ${styles.introP}`}>{props.subTitle}
                </p>
            </div>
            <div className={`mx-5 ${styles.getRequest}`}>
                <button className='btn btn-dark me-3'><a className='text-white' href='/contactus'>Request a qoute</a></button>
                {/* <a href='#'>Get a demo <ArrowForwardIcon />  </a> */}
            </div>
        </div>

    )
}

export default IntroTeams