import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { AddUsersInGroup, getUsers } from "../../servises/api";
import styles from "./AddMemberToGroup.module.css";

export const AddMemberToGroup = (props) => {
    const eyouthPublicClient = JSON.parse(
        localStorage.getItem("userPublic_stu") || {}
    );
    let CompanyName = localStorage.getItem("company_name");
    let primaryColor = localStorage.getItem("primaryColor" || 'black')
    // console.log(primaryColor, "primaryColor");


    const [open, setOpen] = useState(false);
    const [optionsDataUsers, setOptionsDataUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [usersLoading, setUsersLoading] = useState(true);



    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    // Get the users inside the company
    useEffect(() => {
        getUsers('1', CompanyName, "10000", "", "", "")
            .then((result) => {
                setUsersLoading(true)
                const formattedOptionsUsers = result.results.map((item) => ({
                    value: item.email,
                    label: item.username,
                }));
                setOptionsDataUsers(formattedOptionsUsers);
                setUsersLoading(false)

            })
            .catch((error) => {
                console.error("API Error:", error);
            });
    }, [CompanyName]);

    // Add users inside the group
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        await AddUsersInGroup(selectedUsers, props.groupName);
        handleClose();
        window.location.reload();
    };

    return (
        <div className={styles.btnToAddMembers}>
            <button
                className={styles.AddMemberToGroupContainer}
                style={{ background: primaryColor }}
                onClick={handleClickOpen}
            >
                <AddIcon
                    sx={{
                        width: "1.875rem",
                        height: "1.875rem",
                        color: "#FFFFFF",
                    }}
                />
                <h3>Add a new member</h3>
            </button>

            <Dialog open={open} onClose={handleClose} className={styles.modalWindow}>
                <form id="submitForm" onSubmit={handleFormSubmit}>
                    <DialogTitle className={styles.modalHeader}>
                        <h3>Add a new member to this group</h3>
                    </DialogTitle>
                    <DialogContent className={styles.modalContent}>
                        <>
                            <h6> Choose users</h6>
                            <Select
                                isLoading={usersLoading} // Use the isLoading prop to indicate loading state
                                loadingMessage={() => <> loading data.... </>} // Customize the loading message
                                className={styles.selectUsers}
                                isMulti
                                options={optionsDataUsers}
                                onChange={(selectedOptions) => {
                                    const selectedUserLabels = selectedOptions.map(
                                        (option) => option.label
                                    );
                                    setSelectedUsers(selectedUserLabels);
                                    // console.log("values----------", selectedUserLabels);
                                }}
                            />
                        </>
                    </DialogContent>
                    <DialogActions>
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                handleClose();
                            }}
                            className="btn btn-danger border"
                        >
                            Cancel
                        </button>
                        <button
                            form="submitForm"
                            type="submit"
                            className="px-3 btn btn-primary border"
                            style={{
                                background: "#183696",
                            }}
                        >
                            Add
                        </button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
};
