import { TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
    AddUsersInGroup,
    CreateGroupName,
    getCompanyGroups,
    getUsers,
} from "../../servises/api";
import styles from "./CreateGroup.module.css";

export default function CreateGroup() {
    // const { id } = useParams();
    const eyouthPublicClient = JSON.parse(
        localStorage.getItem("userPublic_stu") || {}
    );
    let CompanyName = localStorage.getItem("company_name");

    const [open, setOpen] = useState(false);
    const [optionsDataUsers, setOptionsDataUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [optionsDataGroups, setOptionsDataGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState([]);
    const [groupName, setGroupName] = useState(""); // New state for group name
    const [usersLoading, setUsersLoading] = useState(true);
    const [groupLoading, setGroupLoading] = useState(true);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const [assignTo, setAssignTo] = useState(null);
    const handleAssignToChange = (selectedOption) => {
        setAssignTo(selectedOption);
    };

    useEffect(() => {
        getUsers('1', CompanyName, "10000", "", "", "")
            .then((result) => {
                setUsersLoading(true)
                const formattedOptionsUsers = result.results.map((item) => ({
                    value: item.email,
                    label: item.username,
                }));
                setOptionsDataUsers(formattedOptionsUsers);
                setUsersLoading(false)

            })
            .catch((error) => {
                console.error("API Error:", error);
            });
    }, [CompanyName]);

    useEffect(() => {
        getCompanyGroups(CompanyName)
            .then((result) => {
                setGroupLoading(true)
                const formattedOptionsGroups = result.data.map((item) => ({
                    value: item.group_name,
                    label: item.group_name,
                }));

                setOptionsDataGroups(formattedOptionsGroups);
                setGroupLoading(false)
            })
            .catch((error) => {
                console.error("API Error:", error);
            });
    }, [CompanyName]);

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        if (assignTo && assignTo.value === "NewGroup") {
            await CreateGroupName(CompanyName, groupName);
            await AddUsersInGroup(selectedUsers, groupName);
        } else {
            // console.log(selectedUsers, 'selectedUsers')
            await AddUsersInGroup(selectedUsers, selectedGroup);
        }
        handleClose();
        window.location.reload()
    };

    // disable button

    const isCreateButtonDisabled = () => {
        if (assignTo && assignTo.value === "NewGroup" && !groupName) {
            return true;
        }
        if (assignTo && assignTo.value !== "NewGroup" && !selectedGroup) {
            return true;
        }
        if (selectedUsers.length === 0) {
            return true;
        }
        return false;
    };

    return (
        <div className={styles.btnToAddMembers}>
            <button
                className={styles.AddMemberToGroupContainer}
                onClick={handleClickOpen}
            >
                <AddIcon
                    sx={{
                        width: "1.875rem",
                        height: "1.875rem",
                        color: "#FFFFFF",
                    }}
                />
                <h3>Create a new Group</h3>
            </button>

            <Dialog
                open={open}
                sx={{
                    overflowY: "visible",
                    "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
                        minWidth: "45vw",
                        maxWidth: "80vw",
                        overflowY: "visible"
                    },
                    " &.MuiDialogTitle-root+.css-ypiqx9-MuiDialogContent-root": {
                        minHeight: "50vh",
                        overflowY: "visible"

                    },
                }}
                onClose={handleClose}
            >
                <form
                    id="submitForm"
                    // onSubmit={formikValidation.handleSubmit}
                    onSubmit={handleFormSubmit}
                    style={{ overflowY: "visible" }}
                >
                    <DialogTitle className="text-primary">
                        <h3 style={{ color: "#283B91" }}>Create a New Group</h3>
                    </DialogTitle>
                    <DialogContent
                        sx={{
                            minHeight: "50vh",
                            overflowY: "visible"
                        }}
                    >
                        <FormControl sx={{
                            display: "block",
                            width: "90%"
                        }}>
                            <div>
                                <p className="mx-2 my-2 p-0" style={{ color: "#283B91" }}>
                                    Choose Create New group or Created Group{" "}
                                </p>
                                <Select
                                    className={styles.selectAGroup}
                                    options={[
                                        { value: "OldGroup", label: "Already There Is Group" },
                                        { value: "NewGroup", label: "Create New Group" },
                                    ]}
                                    onChange={handleAssignToChange}
                                    isSearchable={false}
                                />
                                <div
                                    className="d-flex align-items-center"
                                    style={{ width: "40vw" }}
                                >
                                    <div>
                                        {assignTo && (
                                            <div>
                                                {assignTo.value === "NewGroup" ? (
                                                    <>
                                                        <div className="my-5 d-flex me-5">
                                                            <div>
                                                                <TextField
                                                                    id="standard-basic"
                                                                    label="Group Name"
                                                                    onChange={(e) => setGroupName(e.target.value)}
                                                                    variant="standard"
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="my-5 d-flex me-5">
                                                            <div>
                                                                <h6> Choose Group</h6>
                                                                <Select
                                                                    isLoading={groupLoading} // Use the isLoading prop to indicate loading state
                                                                    loadingMessage={() => <> loading data.... </>} // Customize the loading message
                                                                    options={optionsDataGroups}
                                                                    onChange={(selectedOption) => {
                                                                        setSelectedGroup(selectedOption.value);
                                                                        // console.log(selectedOption.value, 'Selected Group:',)
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        )}
                                    </div>

                                    <div className="col-5">
                                        <h6> Choose users</h6>
                                        <Select
                                            isLoading={usersLoading} // Use the isLoading prop to indicate loading state
                                            loadingMessage={() => <> loading data.... </>} // Customize the loading message
                                            options={optionsDataUsers}
                                            isMulti
                                            onChange={(selectedOptions) => {
                                                const selectedUserLabels = selectedOptions.map(
                                                    (option) => option.label
                                                );
                                                setSelectedUsers(selectedUserLabels);
                                                // console.log(selectedUserLabels, "values");
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                handleClose();
                            }}
                            className="btn btn-danger border"
                        >
                            Cancel
                        </button>
                        <button
                            form="submitForm"
                            type="submit"
                            className="btn btn-primary border"
                            style={{
                                background: "#183696",
                            }}
                            disabled={isCreateButtonDisabled()}
                        >
                            Create
                        </button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
}
