import React from "react";
import styles from "./WhyService.module.css";

const WhyService = (props) => {
    return <div className={styles.whyContainer}>
        <div className={styles.whyDescription}>
            <h2>{props.title}</h2>
            {props.subTitle && <h5>{props.subTitle}</h5>}
        </div>
        <div className={styles.cardDetailsContainer}>
            {props.benefits.map((benefit) => (
                <div className={styles.cardsDetail}>
                    <img src={benefit.logo} alt="why service card logo" />
                    <h6>{benefit.description}</h6>
                    {benefit.subDescription && <h6>{benefit.subDescription}</h6>}
                </div>
            ))}
        </div>
    </div>
};

export default WhyService;
