import React from 'react';
import styles from './WhyEYouthTeams.module.css';

function WhyEYouthTeams(props) {
    return (
        <div className={styles.WhyEYouthTeamsBackground}>
            <div className="container mt-5">
                <p>{props.headTitle}</p>
                <h4 className={styles.color_text}>{props.SectionTitle}</h4>
                <div className='d-flex flex-wrap' style={{ gap: "25px" }}>
                    {props.featuresData.map((feature, index) => (
                        <div key={index} className={`${styles.featuresDataStyle} `}>
                            <div className={`${styles.box} d-flex justify-content-center align-items-center`}>
                                <img className='w-100' src={feature.image} alt="" />
                            </div>
                            <h6 className={`${styles.color_text} mt-2`}>{feature.title}</h6>
                            <p>{feature.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default WhyEYouthTeams;
