import React from "react";
import contentImg from "../../assets/Business/09 (3).jpg";
import videos from "../../assets/Business/Group 1597883162.svg";
import quiz from "../../assets/Business/Group 1597883163.svg";
import dataChart from "../../assets/Business/Group 1597883164.svg";
import guide1 from "../../assets/Business/Rectangle.jpg";
import joystick from "../../assets/Business/Vector (2).svg";
import presentation from "../../assets/Business/Vector (3).svg";
import guide2 from "../../assets/Business/image 4.jpg";
import eyouthStudioImg from "../../assets/Business/image 5.jpg";
import uniqueEdImg from "../../assets/Business/image with arrow.jpg";
import studio1 from "../../assets/Business/studio1.jpg";
import studio2 from "../../assets/Business/studio2.jpg";
import studio3 from "../../assets/Business/studio3.jpg";
import IntroTeams from "../../pages/ServiceTeams/IntroTeams/IntroTeams";
import ProductTestimonial from "../ProductTestimonial/ProductTestimonial";
import WhyService from "../WhyService/WhyService";
import styles from "./ServiceStudio.module.css";
import WhyEYouthTeams from "../../pages/ServiceTeams/WhyEYouthTeams/WhyEYouthTeams";

const ServiceStudio = () => {
    const serviceStudioBenefits = [
        {
            logo: joystick,
            description: "Games & Entertainment",
        },
        {
            logo: dataChart,
            description: "Interactive presentation",
        },
        {
            logo: presentation,
            description: "Infographic charts",
        },
        {
            logo: quiz,
            description: "Quizzes & assessments",
        },
        {
            logo: videos,
            description: "Quizzes & assessments",
        },
    ];

    const featuresData = [
        {
            image: studio1,
            title: 'Educational Video Production',
            description: 'Transform your ideas into reality and overcome learning challenges with customized video solutions',
        },
        {
            image: studio2,
            title: 'Customized Learning Experience',
            description: 'Our tailor-made learning programs are designed to meet various learning needs and are offered in both Arabic and English.',
        },
        {
            image: studio3,
            title: 'E-Learning Design',
            description: "Enhance your LMS with customized interactive e Learning programs developed by EYouth's expert instructional designers and market experts with the highest-end quality.",
        }
    ];

    return (
        <>
            <IntroTeams
                imageBackground="serviceStudioBG.png"
                title="A comprehensive suite of services for creating various types of
                learning materials"
                subTitle="EYouth Studio offers a complete stack for all your learning
                production needs which vary from game-based learning, video-based
                learning, interactive-based learning."
            />

            <WhyEYouthTeams SectionTitle='A flexible solution that works for companies of anysize anywhere to drive learning outcomes.' featuresData={featuresData} headTitle='Core Features' />

            <div className={styles.serviceContent}>
                <div className={`${styles.commonArticle} ${styles.interactiveArticle}`}>
                    <img src={contentImg} alt="interactive content" />
                    <div>
                        <h2 className={styles.serviceHeader}>
                            Multiple Types Of interactive content for different learning
                            experiences
                        </h2>
                        <ul className={styles.serviceStudioLists}>
                            <li>Use mechanics of gamification</li>
                            <li>Design interactive modules</li>
                            <li>Consult subject matter experts</li>
                            <li>Adopt the agile methodology</li>
                            <li>Stay on budget</li>
                        </ul>
                    </div>
                </div>

                <div className={`${styles.commonArticle} ${styles.uniqueEducational}`}>
                    <img src={uniqueEdImg} alt="unique educational" />
                    <div>
                        <h2 className={styles.serviceHeader}>
                            Unique educational video production experience to get your own
                            customized videos with Premium quality
                        </h2>
                        <h5 className={styles.serviceSubHeader}>
                            Choose between different video types
                        </h5>
                        <ul className={styles.serviceStudioLists}>
                            <li>Motion Graphic Videos</li>
                            <li>Shooted Videos</li>
                            <li>Screencast Videos</li>
                        </ul>
                    </div>
                </div>

                <div
                    className={`${styles.commonArticle} ${styles.eyouthStudioArticle}`}
                >
                    <img src={eyouthStudioImg} alt="eyouth studio article" />
                    <div>
                        <h2 className={styles.serviceHeader}>
                            With EYouth Studio, you can simply get your own educational videos
                        </h2>
                        <ul className={styles.serviceStudioLists}>
                            <li>Fully Equipped Studio</li>
                            <li>In-House Production Team</li>
                            <li>Consult subject matter experts</li>
                            <li>Fast Delivery With Competitive Pricing</li>
                        </ul>
                    </div>
                </div>

                <div
                    className={`d-block pt-4 ${styles.commonArticle} ${styles.eyouthStudioArticle}`}
                >
                    <h2 className={styles.serviceHeader}>
                        Customize a full learning experience through which trainees live in
                        real work situations through synchronous and asynchronous
                        activities, games, and fun experiences.
                    </h2>
                    <h5 className={styles.serviceSubHeader}>
                        Get your own learning kit:
                    </h5>
                    <div className="d-flex justify-content-between flex-wrap">
                        <ul className={styles.serviceStudioLists}>
                            <li>Facilitator Guide</li>
                            <li>Game Cards & Boards</li>
                            <li>Handouts & Sheets</li>
                            <li>PowerPoint Slides</li>
                            <li>All essential elements</li>
                        </ul>
                        <div className="text-center">
                            <img className="mx-3" src={guide2} alt="guide to platform" />
                            <img src={guide1} alt="guide to platform" />
                        </div>
                    </div>
                </div>
            </div>

            <WhyService
                title="All in one!"
                subTitle="One, seamless solution for best-in-class tailor-made learning designs and experiences"
                benefits={serviceStudioBenefits}
            />
            <ProductTestimonial />
        </>
    );
};

export default ServiceStudio;
