import { Buffer } from 'buffer';
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import './App.css';
import MainNavbar from "./components/MainNavbar/MainNavbar";
import Navbar from './components/navbar/navbar';
import { login, publicLogin } from "./config/state/actions";
import AdminDashboard from "./pages/AdminDashboard/AdminDashboard";
// import CourseEnrollment from './pages/AdminDashboard/CourseEnrollment';
import EmailNotification from "./pages/AdminDashboard/EmailNotification";
// import Groups from './pages/AdminDashboard/Groups';
import LmsTrack from './pages/AdminDashboard/LmsTrack';
import UsersTracking from './pages/AdminDashboard/UsersTracking';
import ContactUs from "./pages/Business-ContactUs/ContactUs";
import CourseEnrollment from './pages/CourseEnrollment/CourseEnrollment';
// import Partners from "./pages/Partners/Partners";
import UserProfile from './pages/UserProfile/UserProfile';
// import Users from './pages/AdminDashboard/Users';
import ServiceStudio from './components/ServiceStudio/ServiceStudio';
import ServiceClass from './components/serviceClass/ServiceClass';
import Groups from './pages/AdminDashboard/Groups';
import ServiceTeams from './pages/ServiceTeams/ServiceTeams';
import { getComapnyData } from './servises/api';
// import { useRouteMatch } from 'react-router-dom';
import GroupDetails from './pages/AdminDashboard/GroupDetails';
// import CourseEnrollment from './pages/AdminDashboard/CourseEnrollment';

const Footer = React.lazy(() => import('./components/footer/footer'));
const CourseDetails = React.lazy(() => import('./pages/courseDetails/CourseDetails'));
const Home = React.lazy(() => import('./pages/home/home'));
const CourseReviews = React.lazy(() => import('./pages/coursereviwes/coursereviwes'));
const B2B = React.lazy(() => import('./pages/B2B/B2B'));
const Studio = React.lazy(() => import('./pages/Studio/Studio'));
const Signup = React.lazy(() => import('./pages/signup/signup'));
const Signin = React.lazy(() => import('./pages/signin/signin'));
const Coursedegree = React.lazy(() => import('./pages/coursedegree/coursedegree'));
const Thankyou = React.lazy(() => import('./pages/thankyou/thankyou'));
const Mycourses = React.lazy(() => import('./pages/myCourses/mycourses'));
const Instructor = React.lazy(() => import("./pages/instructor/Instructor"));
const Instructors = React.lazy(() => import('./pages/instructors/Instructors'));
const ScrollToTop = React.lazy(() => import('./components/ScrollToTop/ScrollToTop'));
const TermsAndCond = React.lazy(() => import('./pages/TermsAndCond/TermsAndCond'));
const PolicyAndPrivecy = React.lazy(() => import('./pages/PolicyAndPrivecy/PolicyAndPrivecy'));
const AddReviews = React.lazy(() => import('./pages/AddReview/AddReview'));
const Logout = React.lazy(() => import('./pages/logout/logout'));
const FilterWithAPIs = React.lazy(() => import('./components/FilterWithAPI/FilterWithAPI'));
const Loading = React.lazy(() => import('./pages/loading/loading'));
// const LastFilter = React.lazy(() => import('./components/current-filter/current-filter'));
const NotFoundPage = React.lazy(() => import('./pages/Not-Found/Not-Found'));

// import addReview from "./pages/addReview/addReview";

// import Coupon from "./pages/Coupon/Coupon";

// 

function App() {
  const location = useLocation()
  const navigate = useNavigate()
  const [URLSearchParams] = useSearchParams()
  const [token, setToken] = useState()
  const dispatch = useDispatch();
  let companyUrl = window.location.host;
  let company_name = companyUrl.split('.', 1)[0];

  localStorage.setItem("company_name", (company_name === "business" || companyUrl === "localhost:3000") ? "EYouth" : company_name);
  let comapnyNameLocalStorage = localStorage.getItem("company_name")




  // if session end sign out 
  const session = JSON.parse(localStorage.getItem("user_stu") || "{}");
  useEffect(() => {
    if (session.expiration && session.expiration < new Date().getTime() / 1000) {
      navigate('/logout')
      localStorage.removeItem("user_stu")
    }
    // eslint-disable-next-line
  }, [new Date().getTime()])


  useEffect(() => {
    const formData = new FormData();
    formData.append("grant_type", "client_credentials");
    formData.append("client_id", "iyex9JdnXt2BgM6sj60BzxMQYXBCwmUupriDI2dS");
    formData.append("token_type", 'jwt');
    formData.append("client_secret", "GdS7hlIhShoXcC1d8uypVH8F4z4aNSsDMO7xF4fIgkBCMl0Qazx9CCHaeUOsTE44cqT36hIYSvjnlj8FcfWPgnE0Zp943t32ShUS5cb6VhXVBTHfHUOQJNECuTqmjYVJ");
    dispatch(publicLogin(formData))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (token?.length > 10)
      localStorage.setItem("user_stu", token);
  }, [token])

  useEffect(() => {
    if (location.pathname.toLocaleLowerCase().includes("Studio")) {
      window.location.href = "https://learning.business.eyouthlearning.com/login?next=/oauth2/authorize%3Fclient_id%3Dcms-sso%26redirect_uri%3Dhttps%253A%252F%252Fstudio.business.eyouthlearning.com%252Fcomplete%252Fedx-oauth2%252F%253Fredirect_state%253Ds7ixquGOYIPbfOci4Uao1Oos0hGUGLKC%26state%3Ds7ixquGOYIPbfOci4Uao1Oos0hGUGLKC%26response_type%3Dcode%26scope%3Duser_id%2Bprofile%2Bemail"
    }
    setToken(Buffer.from(decodeURI(URLSearchParams.get("token")), "base64").toString("utf-8"))
  }, [location, URLSearchParams])

  useEffect(() => {
    const session = JSON.parse(localStorage.getItem("user_stu") || "{}");
    if (session.expiration < new Date().getTime() / 1000) {
      const formData = new FormData();
      formData.append("grant_type", "refresh_token");
      formData.append("client_id", "login-service-client-id");
      formData.append("refresh_token", session.refresh_token);
      dispatch(login(formData))
    }
    // eslint-disable-next-line
  }, [])
  const isDetailsPage = location.pathname.startsWith('/details/');
  const isGroupsPage = location.pathname.startsWith('/groups/');
  const isGroupDetailsPage = location.pathname.startsWith('/admin-dashboard/group-details/');

  const isUserProfilePage = location.pathname.startsWith('/userProfile/');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const company_data = await getComapnyData(comapnyNameLocalStorage);
        let primaryColor = company_data.data[0].primaryColor;
        let secondaryColor = company_data.data[0].secondaryColor;
        localStorage.setItem('primaryColor', company_data.status === 200 ? primaryColor : "black")
        localStorage.setItem('secondaryColor', company_data.status === 200 ? secondaryColor : "white")
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [])


  return (
    <>
      <div className="container-fluid m-0 p-0">
        {/* {location.pathname === '/admin-dashboard' || location.pathname === '/contactus' || location.pathname === '/all-courses' || location.pathname === '/eyouth-teams' ? */}
        {location.pathname === '/admin-dashboard/enrollment' ||
          location.pathname === '/admin-dashboard/users' ||
          location.pathname === '/admin-dashboard/groups' ||
          location.pathname === '/admin-dashboard/lms-track' ||
          location.pathname === '/admin-dashboard/notification' ||
          location.pathname === '/all-courses' ||
          location.pathname === '/mylearnings' ||
          location.pathname === '/admin-dashboard/signup' ||
          isDetailsPage ||
          isUserProfilePage || location.pathname === '/signin' || isGroupsPage || isGroupDetailsPage ? <MainNavbar token={token} /> : <Navbar />}
        {/* :<MainNavbar token={token} /> */}
        {/* {location.pathname === '/' || location.pathname === '/contactus' || location.pathname === '/all-courses' || location.pathname === '/eyouth-teams' ? <Navbar /> : <MainNavbar token={token} />} */}
        {/* {location.hash !== " " && <MainNavbar token={token} />} */}
        {/* {location.pathname !== '/loading' && location.pathname !== '/' && location.pathname !== '/contactus' && location.pathname !== '/all-courses' && } */}

        {/* <SimpleSnackbar /> */}
        <ScrollToTop />
        <div style={{ minHeight: "65vh" }}>
          <Routes>

            <Route path="/" element={<Home />}> </Route>
            {/* <Route path="/admin-dashboard" element={<AdminDashboard />}></Route> */}
            <Route path="/admin-dashboard" element={<AdminDashboard />}>
              <Route index path="users" element={<UsersTracking />}></Route>
              <Route path="enrollment" element={<CourseEnrollment />}></Route>
              <Route path="notification" element={<EmailNotification />}></Route>
              <Route path="lms-track" element={<LmsTrack />}></Route>
              <Route path="groups" element={<Groups />}></Route>
              <Route path="group-details/:groupId" element={<GroupDetails />}></Route>
              <Route path="signup" element={<Signup />}></Route>
            </Route>
            <Route path="/signin" element={<Signin />}></Route>
            <Route path="/home" element={<Home />}> </Route>
            <Route path="/contactus" element={<ContactUs />}> </Route>
            {/* <Route path="/courses-page" element={<LastFilter />}></Route> */}
            {/* <Route path="/CoursesPage"  element={<CoursesPage />}> </Route> */}
            {/* {Object.keys(session).length > 0 && */}
            <Route path="/all-courses" element={<FilterWithAPIs />}> </Route>
            {/* } */}
            <Route path="/thankyou" element={<Thankyou />}> </Route>
            <Route path="/mylearnings" element={<Mycourses />}> </Route>
            <Route path="/instructors" element={<Instructors />}> </Route>
            <Route path="/CourseReviews/:id" element={<CourseReviews />}> </Route>
            <Route path="/instructor/:id" element={<Instructor />}> </Route>
            <Route path="/addReview/:id" element={<AddReviews />}> </Route>
            <Route path="/B2B" element={<B2B />}> </Route>
            <Route path="/studio" element={<Studio />}> </Route>
            <Route path='/serviceStudio' element={<ServiceStudio />}></Route>
            <Route path='/serviceClass' element={<ServiceClass />}></Route>
            <Route path="/coursedegree" element={<Coursedegree />}> </Route>
            <Route path="/details/:id" element={<CourseDetails />}> </Route>
            <Route path="/TermsAndCond" element={<TermsAndCond />}></Route>
            <Route path="/Policy" element={<PolicyAndPrivecy />}></Route>
            <Route path="/logout" element={<Logout />}></Route>
            <Route path="/loading" element={<Loading />}></Route>
            {/* <Route path="/partners" element={<Partners />}></Route> */}
            <Route path="/userProfile/:id" element={<UserProfile />}></Route>
            <Route path="/course-enrollment" element={<CourseEnrollment />}></Route>
            <Route path="/eyouth-teams" element={<ServiceTeams />}></Route>


            {/* <Route path="/coupon" element={<Coupon />}></Route> */}
            <Route path="*" element={<NotFoundPage />} /> </Routes>

        </div>

        {location.pathname !== '/loading' && <Footer />}
      </div>

    </>

  );
}

export default App;