import React from 'react'
import styles from './Reporting.module.css'
import FeaturesTrackImage from '../../../assets/Business/Device - Macbook Pro.png'

function Reporting() {
    return (
        <div className={`d-flex container align-items-center mt-2 ${styles.divReporting}`}>
            <div className='col-6'>
                <h4 className={`${styles.text_color} mb-3 `}>Advanced Reporting</h4>
                <p>Track all of your employees actions, progress and completion in real time.</p>
                <p>Extract various reports at user, department and company level.</p>
            </div>
            <div className='col-6'>
                <img className='w-75' src={FeaturesTrackImage} alt="" />
            </div>

        </div>
    )
}

export default Reporting