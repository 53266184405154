import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import logoBlue from '../../assets/Business/EYouth-business-black.png';
import logoWhite from '../../assets/Business/EYouth-business-white.png';
import logoteams from '../../assets/Business/EYouth Business Teams Black.png';
import Services from "../../config/services/authServices";
import { getComapnyData } from "../../servises/api";
import "./navbar.css";

const Navbar = () => {
  // const [profilePic, setProfilePic] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  // const [URLSearchParams, setURLSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  // eslint-disable-next-line 
  const [session, setSession] = useState(JSON.parse(localStorage.getItem("user_stu") || "{}"));
  let companyName = localStorage.getItem("company_name");


  const [navbar, setNavbar] = useState(false);
  const [navbarLogo, setNavbarLogo] = useState(logoBlue);
  // eslint-disable-next-line
  const [comapnyLogo, setComapnyLogo] = useState("");

  // const [showEyouthTeams, setShowEyouthTeams] = useState(false);
  const open = Boolean(anchorEl);


  const changeBackground = () => {
    setNavbar(window.scrollY >= 66);
  };

  const changeLogo = () => {
    setNavbarLogo(window.scrollY >= 60 ? logoWhite : logoBlue);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = async () => {
    handleClose();
    localStorage.removeItem("user_stu");
    await Services.logout();
    navigate("/");
  };

  useEffect(() => {
    changeBackground();
    changeLogo();
    window.addEventListener("scroll", changeBackground);
    window.addEventListener("scroll", changeLogo);

    return () => {
      window.removeEventListener("scroll", changeBackground);
      window.removeEventListener("scroll", changeLogo);
    };
  }, []);

  const navigateHome = () => navigate("/");
  let primaryColor = localStorage.getItem("primaryColor" || 'black');
  // console.log(primaryColor, "primaryColor");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const company_data = await getComapnyData("EYouth");
        // console.log(company_data.data[0].logo, "company_data.data");
        setComapnyLogo(company_data.data[0].logo);

      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);




  return (
    <div className="nav--bar NAV">
      <div className={navbar ? "navbar main-nav-padding w-100 p-1 px-3" : "d-flex justify-content-between align-items-center w-100 nav--bar-two main-nav-padding p-1 px-3"} style={{ background: navbar ? `${primaryColor}` : "" }}>
        <div className='d-flex justify-content-between'>
          <img alt="eyouth-logo" loading=" lazy" src={companyName === "EYouth" ? navbarLogo : logoteams} className="logo--image ms-3" onClick={navigateHome} />
          {/* {Object.keys(session).length > 0 && <img alt="eyouth-logo" loading="lazy" src={`https://business-django.eyouthlearning.com${comapnyLogo}`} className="logo--image other-company-logo ms-3" onClick={navigateHome} />} */}
        </div>

        <div className='d-flex gap-4 align-items-center'>
          {!session.is_admin && <NavLink to="/" className="text-dark">Home</NavLink>}
          {Object.keys(session).length === 0 ? (
            <div className="navigation_content_last">
              <div className="dropdown">
                <span style={{ fontSize: "16px" }} className="nav-link d-flex hover-underline-animation mx-1">
                  Services <KeyboardArrowDownIcon />
                </span>

                <div className="dropdown-content">
                  <a style={{ fontSize: "16px" }} className="d-flex" href="/eyouth-teams">
                    EYouth Teams
                  </a>
                  <a style={{ fontSize: "16px" }} className="d-flex" href="/serviceStudio">
                    EYouth Studio
                  </a>

                  <a style={{ fontSize: "16px" }} className="d-flex" href="/serviceClass">
                    EYouth Class
                  </a>
                  <a style={{ fontSize: "16px" }} className="d-flex" href="/contactus">
                    EYouth LMS
                  </a>
                </div>
              </div>
            </div>


          ) : <NavLink to="/all-courses" className="text-black"> Courses </NavLink>}
          {session.is_admin && <NavLink className="text-black" to="/admin-dashboard">Dashboard</NavLink>}

          {Object.keys(session).length > 0 && (
            <div>
              <Button
                id="fade-button"
                aria-controls={open ? "fade-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                className="profile-btn  profile-btn-navbar border-button rounded p-1"
              >
                {!isMobile ? (
                  <>
                    <p className="username-button mx-1">{session?.username}</p>
                  </>) : (
                  <AccountCircleIcon className="account-circle" />
                )}
                <KeyboardArrowDownIcon />
              </Button>
              <Menu
                style={{ zIndex: "999999" }}
                id="fade-menu"
                MenuListProps={{
                  "aria-labelledby": "fade-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
              >
                <MenuItem onClick={() => (window.location.href = `https://apps.learning.business.eyouthlearning.com/profile/u/${session?.username}`)}>
                  <Link> Profile</Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/mylearnings">My Learnings</Link>
                </MenuItem>
                <MenuItem onClick={handleLogOut}>
                  <button className="btn btn-light border m-auto">Sign Out</button>
                </MenuItem>
              </Menu>
            </div>
          )}
          {Object.keys(session).length === 0 && pathname !== "/signin" && (
            <>
              <button className={navbar ? 'btn btn-primary text-white' : ' btn btn-dark  text-white'}>
                <Link to="/contactus" className="text-white">
                  Request a quote
                </Link>
              </button>
              <a href="https://eyouthlearning.com/" target='_blank' className="text-blue !important">
                <button className={navbar ? "eyouthBtn" : "eyouthBtnDown"}>
                  View EYouth Platform
                </button>
              </a>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;