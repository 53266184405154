import AddHomeIcon from "@mui/icons-material/AddHome";
import { Alert, Box, CircularProgress, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CreateGroup from "../../components/CreateGroup/CreateGroup";
import UserCards from "../../components/UserCards/UserCards";
import { getCompanyGroups } from "../../servises/api";
import styles from "./UsersTracking.module.css";

function Groups() {
    const [optionsDataGroups, setOptionsDataGroups] = useState([]);
    const [loading, setLoading] = useState(false)
    const [state, setState] = useState({
        open: false,
        vertical: "top",
        horizontal: "right",
    });

    const { vertical, horizontal, open } = state;
    let primarycolor = localStorage.getItem("primaryColor");


    const handleClose = () => {
        setState({ ...state, open: false });
    };

    const eyouthPublicClient = JSON.parse(
        localStorage.getItem("userPublic_stu") || {}
    );
    const companyName = localStorage.getItem("company_name");

    //calling groups API
    useEffect(() => {
        getCompanyGroups(companyName)
            .then((result) => {
                setOptionsDataGroups(result.data);
                setLoading(true);
            })
            .catch((error) => {
                console.error("API Error:", error);
            });
        setLoading(false);
    }, [companyName]);

    return (
        <>
            <Snackbar
                key={vertical + horizontal}
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                autoHideDuration={20000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
                    Your Request is being processed, Please don't refresh the page.
                </Alert>
            </Snackbar>

            <div
                style={{ justifyContent: "space-between" }}
                className={styles.businessInfoCards}
            >
                <UserCards
                    count={optionsDataGroups.length}
                    title="Created Group"
                    Icon={<AddHomeIcon style={{ fontSize: "40px" }} />}
                />

                <CreateGroup />
            </div>

            <div className={`container ${styles.groupCardContainer}`}>
                {!loading ? (
                    <tr>
                        <td style={{ textAlign: "center" }}>
                            <Box
                                style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                }}
                            >
                                <CircularProgress
                                    size={50}
                                    style={{ color: primarycolor }}
                                />
                            </Box>
                        </td>
                    </tr>
                ) : (optionsDataGroups.map((group, i) => (
                    <Link to={`/admin-dashboard/group-details/${group.name}`} key={i}>
                        <div className={styles.groupCard}>
                            <h3>{group.group_name}</h3>
                        </div>
                    </Link>
                )))}
            </div>
        </>
    );
}

export default Groups;
