import React from 'react'
import platformImage from '../../../assets/Business/11.png'
import platformImage2 from '../../../assets/Business/platformNoDevloper.png'

function LearningPlatform() {
    return (
        <div className='container'>
            <div className='d-flex align-items-center'>
                <div className='col'>
                    <h2 style={{ color: "#183696", fontWeight: "bolder", width: "85%" }}>The fastest and effortless way to get your own branded, expandable learning platform is here</h2>
                    <p style={{ width: "85%" }}>Our learning platform comes with the most powerful monitoring and reporting capabilities</p>
                </div>
                <div className='col'>
                    <img src={platformImage} width='90%' alt="platformImage" />
                </div>
            </div>
            <div className='my-4'>
                <h2 className='text-center' style={{ color: "#183696" }}>Get your learning platform with NO need for developers</h2>
                <p className='text-center'>Offer anytime access to courses on business, tech, leadership, and more</p>
                <div className='d-flex justify-content-center'>
                    <img src={platformImage2} width='60%' alt="" />
                </div>
            </div>
        </div>
    )
}

export default LearningPlatform