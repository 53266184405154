import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Fade from "@mui/material/Fade";
import MenuItem from "@mui/material/MenuItem";
import logoBlue from '../../assets/Business/EYouth Business Teams Black.svg';
import "./MainNavbar.css";

import * as React from "react";

import {
    Link,
    useLocation,
    useNavigate,
    useSearchParams,
} from "react-router-dom";

import axios from "axios";
import { Buffer } from "buffer";
import { useEffect, useState } from "react";
import Services from "../../config/services/authServices";

import PersonIcon from '@mui/icons-material/Person';
import { Button, Menu } from "@mui/material";
import { isMobile } from "react-device-detect";
import { NavLink } from "react-router-dom";
import { getComapnyData } from "../../servises/api";


export default function MainNavbar() {


    const [profilePic, setProfilePic] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [comapnyLogo, setComapnyLogo] = useState("");
    const [enrollmentType, setEnrollmentType] = useState("");


    const [URLSearchParams, SetURLSearchParams] = useSearchParams();
    const [session, setSession] = useState(
        JSON.parse(localStorage.getItem("user_stu") || "{}")
    );
    const navigateHome = () => navigate("mylearnings");

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleLogOut = async () => {
        handleClose();
        localStorage.removeItem("user_stu");
        await Services.logout();
        navigate("/");
    };




    const [token, setToken] = useState();
    useEffect(() => {
        if (token?.length > 10) {
            localStorage.setItem("user_stu", token);
            setSession(JSON.parse(localStorage.getItem("user_stu") || "{}"));
        }
    }, [token]);

    useEffect(() => {
        setToken(
            Buffer.from(decodeURI(URLSearchParams.get("token")), "base64").toString(
                "utf-8"
            )
        );
        setSession(JSON.parse(localStorage.getItem("user_stu") || "{}"));
    }, [location, URLSearchParams]);

    useEffect(() => {
        axios
            .get(
                `https://learning.business.eyouthlearning.com/api/user/v1/accounts/${session.username}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `bearer ${session.access_token}`,
                    },
                }
            )
            .then((res) => {
                setProfilePic(res.data.profile_image.image_url_full);
            })
            .catch((err) => console.log({ err }));
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let comanyName = localStorage.getItem("company_name")
                const company_data = await getComapnyData(comanyName);
                setComapnyLogo(company_data.data[0].logo)
                setEnrollmentType(company_data.data[0].enrollment_type)
                // console.log(company_data.data[0].enrollment_type, "company_data.data[0].enrollment_type");

            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [])


    return (
        <>
            <div className="nav--bar NAV" >

                <div className={Object.keys(session).length > 0 ? "d-flex justify-content-between align-items-center w-100" : "d-flex justify-content-between align-items-center w-100 main-nav-padding"}>
                    <div className="col-3 d-flex align-items-center">
                        <img
                            alt="eyouth-logo "
                            loading="lazy"
                            src={logoBlue}
                            className="ms-3 logo-teams-width"
                            onClick={navigateHome}
                        />
                        <img
                            alt="eyouth-logo "
                            loading="lazy"
                            // https://business-django.eyouthlearning.com/media/company_logos_files/pepsi.png
                            src={`https://business-django.eyouthlearning.com${comapnyLogo}`}
                            className="logo--image other-company-logo ms-3"
                            onClick={navigateHome}
                        />
                    </div>

                    <div className={Object.keys(session).length === 0 || !isMobile ? "col-4 d-flex justify-content-end align-items-center  navbar-responive-links me-3" : "col-4 d-flex justify-content-end align-items-center navbar-responive-links me-3 "}>
                        <div className=" w-75  d-flex justify-content-around align-items-center " >
                            {/* {Object.keys(session).length === 0 && enrollmentType === "selfEnroll" ?
                                <NavLink className={({ isActive }) => isActive ? 'active-nav' : 'inactive'} to="/all-courses"> Courses</NavLink> :
                                <NavLink className={({ isActive }) => isActive ? 'active-nav' : 'inactive'} to="/mylearnings"> My Courses </NavLink>
                            } */}

                            {Object.keys(session).length > 0 &&
                                (enrollmentType === "selfEnroll" || session.is_admin === true ?
                                    <NavLink className={({ isActive }) => isActive ? 'active-nav' : 'inactive'} to="/all-courses"> Courses</NavLink> :
                                    <NavLink className={({ isActive }) => isActive ? 'active-nav' : 'inactive'} to="/mylearnings"> My Courses </NavLink>
                                )
                            }

                            {session.is_admin === true && <NavLink className={({ isActive }) =>
                                isActive ? 'active-nav' : 'inactive'
                            } to="/admin-dashboard/users">Dashboard</NavLink>}

                            {Object.keys(session).length > 0 && pathname !== "/studio" && (
                                <div>
                                    <Button
                                        id="fade-button"
                                        aria-controls={open ? "fade-menu" : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? "true" : undefined}
                                        onClick={handleClick}
                                        className="profile-btn bg-transparent  border-button rounded p- text-dark"
                                    >
                                        {/* <PersonIcon className="profile-btn" /> */}

                                        {!isMobile ? (
                                            <>
                                                <p className="username-buttonTWO">
                                                    {session?.username}
                                                </p>

                                            </>
                                        ) : (

                                            <AccountCircleIcon className="account-circle fs-6" />
                                        )}
                                        <KeyboardArrowDownIcon />
                                    </Button>
                                    <Menu
                                        style={{ zIndex: "999999" }}
                                        id="fade-menu"
                                        MenuListProps={{
                                            "aria-labelledby": "fade-button",
                                        }}
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        TransitionComponent={Fade}
                                    >


                                        <MenuItem onClick={handleClose} className="fs-6">
                                            <Link to="/mylearnings" style={{ color: "#212121" }}>My Learnings{" "}</Link>
                                        </MenuItem>
                                        <MenuItem onClick={handleClose} className="fs-6">
                                            <h5
                                                onClick={() =>
                                                    (window.location.href = `https://apps.learning.business.eyouthlearning.com/profile/u/${session?.username}`)
                                                }
                                            >
                                                profile
                                            </h5>
                                        </MenuItem>
                                        <MenuItem onClick={handleLogOut}>
                                            <button className="sign-out-btn">
                                                Sign Out
                                            </button>
                                        </MenuItem>
                                    </Menu>

                                </div>
                            )}
                            {Object.keys(session).length === 0 &&
                                pathname !== "/signup" &&
                                pathname !== "/studio" && (
                                    <NavLink className={({ isActive }) =>
                                        isActive ? 'active-nav' : 'inactive'
                                    } to="/signin"><PersonIcon className="person-icon" />Login   </NavLink>
                                )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}