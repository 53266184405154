import LockIcon from '@mui/icons-material/Lock';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment } from "@mui/material";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import { ChangeUserPassword } from "../../servises/api";

export default function ForgetpasswordUser(props) {
    const [alert, setAlert] = useState(false);
    const [errorAlert, setErrorAlert] = useState(false);
    // const [newPassword, setNewPassword] = useState("");


    // useEffect(() => {
    //     const timer = window.setTimeout(() => {
    //       setAlert(false)
    //       handleClose()
    //     }, 5000);
    //     return () => {
    //       window.clearTimeout(timer);
    //     };

    //   }, [alert]);
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    const formikValidation = useFormik({
        enableReinitialize: true,
        initialValues: { password: "" },
        validationSchema: yup.object({
            // email: yup.string().required("البريد الالكتروني مطلوب"),
            password: yup.string().required("password required ")
        }),
        onSubmit: async (values, actions) => {
            try {
                const result = await ChangeUserPassword(props.token, props.username, values.password);

                // console.log("ChangeUserPassword result:", result);

                if (result === 200) {
                    setAlert(true);
                    actions.resetForm()

                } else {
                    setErrorAlert(true);
                }
            } catch (error) {
                setErrorAlert(true);
            } finally {
                handleClose()
            }
        },
    });
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (e) => {
        e.preventDefault();
        setOpen(false);
    };

    return (
        <>
            <span
                onClick={handleClickOpen}
                className="text-end mt-1"
                style={{ color: "#183696", textDecoration: "underLine", cursor: "pointer" }}
            >
                Change password
            </span>
            {/* forget password dialog */}

            <Dialog open={open} onClose={handleClose}>
                <form id="submitForm" onSubmit={formikValidation.handleSubmit}>
                    <DialogTitle className="text-primary">
                        Change  Password For this user
                    </DialogTitle>

                    <DialogContent className="">
                        <label>New Password </label>
                        <TextField
                            label=""
                            placeholder="password"
                            color="primary"
                            fullWidth
                            name="password"
                            id="password"
                            variant="filled"
                            type={showPassword ? "text" : "password"} // <-- This is where the magic happens
                            value={formikValidation.values.password}
                            onChange={formikValidation.handleChange}
                            onBlur={formikValidation.handleBlur}
                            error={formikValidation.touched.password && Boolean(formikValidation.errors.password)}
                            helperText={formikValidation.touched.password && formikValidation.errors.password}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}

                                        >
                                            {showPassword ? <Visibility className="text-color" /> : <VisibilityOff className="text-color" />}
                                        </IconButton>
                                    </InputAdornment>
                                ), startAdornment: (
                                    <InputAdornment position="start">
                                        <LockIcon className="text-color" />
                                    </InputAdornment>
                                ),
                                disableUnderline: true,
                            }}
                        />

                    </DialogContent>
                    {alert && (
                        <div className="d-flex justify-content-center my-2">
                            <Alert variant="outlined" severity="success" className="w-75 ">
                                password changed successfully for this user
                            </Alert>
                        </div>
                    )}
                    {errorAlert && (
                        <div className="d-flex justify-content-center my-2">
                            <Alert variant="outlined" severity="error" className="w-75 ">
                                There was an error, please try again
                            </Alert>
                        </div>
                    )}

                    <DialogActions>
                        <button onClick={handleClose} className="btn btn-danger border">
                            Cancel
                        </button>
                        <button
                            form="submitForm"
                            type="submit"
                            className="btn btn-primary border"
                            style={{
                                background: "#183696"
                            }}
                        >
                            Set Password
                        </button>
                    </DialogActions>
                </form>
            </Dialog >
        </>
    );
}
